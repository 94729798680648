import {mapState} from 'vuex'

export default {
    computed: {
        ...mapState({
            months: state => state.months,
            days: state => state.days
        })
    },
    methods: {
        formatDate(date, format) {
            if (!date) return "";
            const d = new Date(date)
            const day = d.getDate()
            const weekDay = d.getDay()
            const month = d.getMonth()
            return format
                .replace('_Y_', d.getFullYear())
                .replace('_m_', month)
                .replace('_M_', this.prependZero(month + 1))
                .replace('_Month_', this.months[month])
                .replace('_D_', this.prependZero(day))
                .replace('_Day_', this.days[weekDay])
        },
        prependZero(value) {
            return value > 9 ? value : `0${value}`
        }
    }
}