<template>
    <div>
        <div class="d-flex align-items-center justify-content-center">
            <b-button class="font-weight-bold"
                      variant="link"
                      @click="() => { navAction(-1) }"><
            </b-button>
            <p class="family-bold">{{ displayTitle }}</p>
            <b-button class="font-weight-bold"
                      variant="link"
                      @click="() => { navAction(1) }">>
            </b-button>
        </div>
        <slot :split="displayList"></slot>
    </div>
</template>

<script>
import formatDate from '@/mixins/format-date'
import {ObjExtreme} from '@/utilities'

export default {
    name: 'time-splitter',
    mixins: [formatDate],
    props: {
        list: Array,
        splitByMonth: Boolean,
        prop: String,
        title: {
            type: String,
            default: 'Année _Y_'
        }
    },
    created() {
        this.list.forEach(item => {
            const date = new Date(item[this.prop])
            const year = date.getFullYear();

            if (!this.lists[year]) {
                this.lists[year] = this.splitByMonth ? {} : [];
            }

            if (this.splitByMonth) {
                const month = date.getMonth()

                if (!this.lists[year][month]) {
                    this.lists[year][month] = [];
                }

                this.lists[year][month].push(item)
            } else {
                this.lists[year].push(item)
            }
        })


        const maxYear = ObjExtreme('max', this.lists)
        this.year = Math.min(this.$moment().year(), maxYear)
        // this.year = ObjExtreme('max', this.lists)
        if (this.splitByMonth) {
            const maxMonth = ObjExtreme('max', this.lists[this.year])
            this.month = Math.min(this.$moment().month(), maxMonth)
            // this.month = ObjExtreme('max', this.lists[this.year])
        }
    },
    data() {
        return {
            year: null,
            month: 0,
            lists: {},
            isLastYear: false
        }
    },
    computed: {
        displayTitle() {
            return this.formatDate(`${this.year}-${this.prependZero(this.month + 1)}-01`, this.title)
        },
        displayList() {
            if (!this.year || (this.splitByMonth && (this.month === null || this.month === undefined))) return []
            return this.splitByMonth ? this.lists[this.year][this.month] : this.lists[this.year]
        },
    },
    methods: {
        navAction(dir) {
            if (this.splitByMonth) {
                this.changeMonth(dir)
            } else {
                this.changeYear(dir)
            }
            this.$emit('changeDate', {month: this.month, year: this.year})
        },
        changeYear(dir) {
            const min = ObjExtreme('min', this.lists)
            const max = ObjExtreme('max', this.lists)
            let cur = Number(this.year)
            while (cur >= min && cur <= max) {
                cur += dir
                if (this.lists[cur]) {
                    this.year = cur
                    this.isLastYear = false
                    return
                }
            }
            this.isLastYear = true
        },
        changeMonth(dir) {
            const min = ObjExtreme('min', this.lists[this.year])
            const max = ObjExtreme('max', this.lists[this.year])
            let cur = Number(this.month)
            while (cur >= min && cur <= max) {
                cur += dir
                if (this.lists[this.year][cur]) {
                    this.month = cur
                    return
                }
            }
            this.changeYear(dir)
            if (!this.isLastYear) {
                this.month = dir > 0 ? ObjExtreme('min', this.lists[this.year]) : ObjExtreme('max', this.lists[this.year])
            }
        }
    }
}
</script>