<template>
    <b-modal @hide="onHide" scrollable size="xl" v-model="showModal" hide-header hide-footer>
        <div style="height: 90vh; width: 100%">
            <div v-if="!this.pdf" class="w-100 h-100 d-flex justify-content-center align-items-center">
                <b-spinner type="grow" variant="primary" label="Spinning"></b-spinner>
            </div>
            <div v-if="isMobile" class="h-100 w-100">
                <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                    <button class="btn btn-primary" @click="dl()">
                        Télécharger le document
                    </button>
                </div>
            </div>
            <object v-else :data="pdf" type="application/pdf" class="h-100 w-100">
                <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                    <button class="btn btn-primary" @click="dl()">
                        Télécharger le document
                    </button>
                </div>
            </object>
        </div>
    </b-modal>
</template>

<script>
import downloadFile from '@/mixins/download-file'
import AlertHandler from "@/alertHandler";

export default {
    name: 'pdf-viewer',
    mixins: [downloadFile],
    props: {
        promise: Promise,
    },
    mounted() {
        if (/Android|iPhone/i.test(navigator.userAgent)) {
            this.isMobile = true
        }
        this.promise
            .then(res => {
                this.pdf = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}))
            })
            .catch(error => {
                AlertHandler.show({code: 4008, error});
            })
    },
    watch: {},
    data() {
        return {
            pdf: null,
            isMobile: false,
            showModal: true,
        }
    },
    methods: {
        onHide() {
            this.$emit('exit-pdf')
        },
        dl() {
            const link = document.createElement('a');
            link.href = this.pdf;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.onHide();
        }
    }
}
</script>
